.editorClassName {
    min-height: 800px;
}

.highlightOption {
    background: #f00 !important;
    color: #fff;
}

.chip {
    align-items: center;
    background: red !important;
    border-radius: 11px;
    color: #fff;
    display: inline-flex;
    font-size: 13px;
    line-height: 19px;
    margin-bottom: 5px;
    margin-right: 5px;
    padding: 4px 10px;
}