@media (min-width: 576px) {
  .more_files {
    max-width: 94% !important;
  }
}

.img_item_section {
  flex-wrap: wrap;
}

.img_item_section .grabbable {
  flex: initial !important;
  width: 25% !important;
  cursor: move !important;
  padding: 4PX;
}

.img_item_section .grabbable img {
  width: 100%;
}

.large_modal {
  min-width: 90%
}

.form_group_box {
  height: 100%;
  display: flex;
  align-items: center;
}

.form_group_box .form-check {
  display: flex;
  align-items: center;
}

.form_group_box .form-check label {
  margin-bottom: 0;
}

.modal_form {
  z-index: -999 !important;
}

@media (min-width: 576px) {
  .modal_form.modal-dialog {
    margin-right: auto;
    margin-left: auto;
    max-width: 700px !important;
  }
}


.dropdown {
  position: relative;
  color: #333;
  cursor: default;
}

.dropdown .arrow {
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: " ";
  display: block;
  height: 0;
  margin-top: 0.3rem;
  position: absolute;
  right: 10px;
  top: 14px;
  width: 0;
}

.dropdown .arrow.open {
  border-color: transparent transparent #999;
  border-width: 0 5px 5px;
}

.dropdown .selected-value input {
  line-height: 1.5;
  font-size: 1rem;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 2px;
  box-sizing: border-box;
  cursor: default;
  outline: none;
  padding: 8px 52px 8px 10px;
  transition: all 200ms ease;
  width: 100%;
}

.dropdown .options {
  display: none;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  margin-top: -1px;
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 1000;
  -webkit-overflow-scrolling: touch;
}

.dropdown .options.open {
  display: block;
}

.dropdown .option {
  box-sizing: border-box;
  color: rgba(51, 51, 51, 0.8);
  cursor: pointer;
  display: block;
  padding: 8px 10px;
}

.dropdown .option.selected,
.dropdown .option:hover {
  background-color: #f2f9fc;
  color: #333;
}

.controls {
  display: none;
}

.image_contain:hover .controls {
  display: block;
}

.copy_button {
  background-color: white;
  border-radius: 2px;
  padding: 1px 5px;
  cursor: pointer;
}

/* .iSAVrt .rdt_TableCol:first-child {
  width: 40px !important;
  flex-grow: initial !important;
  padding: 0px 5px;
  min-width: 25px;
}

.dwPlXY .ifdffW:first-child {
  width: 40px !important;
  flex-grow: initial !important;
  padding: 0px 5px;
  min-width: 25px;
} */

.image_placeholder {
  border: 1px dashed #2B3991;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.usertable .hkULGy,
.usertable .svYMe {
  /* display: none !important; */
}

.audio-player {}

/* .audio-player .controls{

} */

.audio-player button {
  background-color: #ff0000;
  width: 30px;
  height: 30px;
  border-radius: 40px;
  border: none;
  font-size: 20px;
  color: #fff;
}

.para p {
  margin-top: 0;
  margin-bottom: 0;
}

.custom-modal-body {
  max-width: 800px;
  margin: auto;
}

.right-section-breadcrumb {
  display: flex;
  align-items: center;
}

.right-section-breadcrumb button {
  margin-right: 20px;
}

.hbgCLW,
.bwjPlK {
  font-size: 12px !important;
}

.img-banner {
  width: 600px;
  height: 300px;
  margin-bottom: 20px;
  position: relative;
}

.img-banner img {
  width: 100%;
  height: 100%;
}

.img-banner .cross-btn {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background-color: red;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -15px;
  right: -15px;
  cursor: pointer;
}

.img-banner .cross-btn i {
  font-size: 26px;
  color: white;
}