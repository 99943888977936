.flip-card {
    background-color: transparent;
    width: 300px;
    height: 200px;
    border: 1px solid #f1f1f1;
    perspective: 1000px; /* Remove this if you don't want the 3D effect */
    margin-left: 10px;
    margin-right: 10px;
  }
  
  /* This container is needed to position the front and back side */
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }
  
  /* Do an horizontal flip when you move the mouse over the flip box container */
  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }
  
  /* Position the front and back side */
  .flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
    padding-top:20px;
    padding-bottom:20px;
  }
  
  /* Style the front side (fallback if image is missing) */
  .flip-card-front {
    background-color: #3b5de7;
    color: #fff;
    border-radius: 15px;
  }

  .flip-card-front span {
      color: #fff
  }
  .flip-card-front p {
      color: #fff;
      font-size: 20px
  }
  
  /* Style the back side */
  .flip-card-back {
    background-color: #3b5de7;
    color: #fff;
    border-radius: 15px;
    transform: rotateY(180deg);
  }

  .flip-card-back p {
    color: #fff;
    font-size: 20px
  }

  .flip-card-back span {
    color: #fff;
    display: block
  }

  .flip-card-back .action {
    display: flex;
    justify-content: space-around;
    background-color: transparent;
    margin-top: 10px;
  }

  .flip-card-back Button {
    color: #fff;
    border-width: 1px;
    border-color: #fff;
    background-color: transparent;
    padding:5px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 20px;
  }

  .flip-card-back Button:hover {
    color: #fff;
    border-width: 1px;
    border-color: #fff;
    background-color: transparent;
    padding:5px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 20px;
  }

  .calpicker {
    background-color: transparent;
    margin-left: 10px;
    width:80px
  }
  .calpicker:focus-visible {
    outline: none;
  }