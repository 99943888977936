.tab-active {
    background-color: #ef673b;
    padding: 5px; 
    text-align: center; 
    border-radius: 5px;
    color: #fff;
    border: 1px dashed #ef673b;
}

.tab {
    padding: 5px; 
    text-align: center; 
    border-radius: 5px;
    color: #ef673b;
    cursor: pointer;
    border: 1px dashed #ef673b;
    border-top: none;
}

.my-msg{
    display: flex;
    justify-content: right;
    margin-right: 50px;
  }
  
  .other-msg{
    display: flex;
  }
  
  .img-view {
    width : 40px; 
    height:40px; 
    border-radius: 40px;
    padding: 2px;
    overflow: hidden;
    text-align: center;
    display: flex;
  }
  
  .img {
    width:'100%';
    height: '100%';
    border-radius: 30px;
    --webkit-user-select: none;
  }
  
  .msg-view{
    padding:8px;
    border-radius: 5px;
    padding-left: 10px;
    padding-right: 10px;
  }
  
  .msg{
    color: white;
    font-size: 12px;
    font-weight: bold;
    padding: 5px;
    border-radius: 5px;
    padding-left: 10px;
    padding-right: 10px;
  }
  
  .name {
    color:black;
    font-size: 12px;
    font-weight: bold;
    margin-right: 10px;
    margin-left: 10px;
  }
  
  .my-time{
    margin-right: 10px;
    float:right;
    font-size: 12px;
  }
  